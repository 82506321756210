module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oslobukta City Guide","short_name":"Oslobukta City Guide","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","icon":"src/staticfiles/icons/oslobukta192x192.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7a55f6eabc343cec89695f50506da444"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-544FPCD","includeInDevelopment":true,"routeChangeEventName":"City Guide Route change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
